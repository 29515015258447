import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./scss/App.scss";
import photo from "./assets/imgs/app-illustration.svg";
import logo from "./assets/imgs/logo.svg";
import googlePlay from "./assets/imgs/google-play.png";
import appStore from "./assets/imgs/app-store.svg";

function App() {
  return (
    <section className="main">
      <Container>
        <Row className="align-items-center">
          <Col lg={7} md={6}>
            <h3 className="main-title mb-0">Conheça o aplicativo</h3>
            <h1 className="main-title main-liv">LIV Saúde</h1>

            <p className="main-desc">
              Agendar consultas, emitir 2ª via de boleto, solicitar
              autorizações, consultar unidades próximas a você e muitos outros
              recursos na palma da sua mão.
            </p>

            <h4 className="main-subtitle">
              Baixe agora mesmo na loja de aplicativos do seu smartphone!
            </h4>
            <ul className="main-store">
              <a
                href="https://play.google.com/store/apps/details?id=com.saudeliv&hl=pt_BR"
                target="_blank"
                rel="noopener noreferrer"
                className="main-store-link"
              >
                <li className="main-store-item">
                  <img
                    src={googlePlay}
                    alt="Link para baixar o aplicativo na Play Store"
                    className="main-store-img"
                  />
                </li>
              </a>
              <a
                href="https://apps.apple.com/br/app/liv-sa%C3%BAde/id1472611154"
                target="_blank"
                rel="noopener noreferrer"
                className="main-store-link"
              >
                <li className="main-store-item">
                  <img
                    src={appStore}
                    alt="Link para baixar o aplicativo na App Store"
                    className="main-store-img"
                  />
                </li>
              </a>
            </ul>

            <img src={logo} alt="" className="main-logo" />
          </Col>
        </Row>
      </Container>
      <img
        src={photo}
        className="main-photo"
        alt="Mão segurando celular com o aplicativo da LIV Saúde"
      />
    </section>
  );
}

export default App;
